
import * as ipxRuntime$5i8M7egjWW from '/app/node_modules/.pnpm/@nuxt+image@1.8.1_idb-keyval@6.2.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.27.2/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "eu2.contabostorage.com"
  ],
  "alias": {
    "/products": "https://eu2.contabostorage.com/5f488f75cfa643c9ab46f210c815ab43:products",
    "/employees": "https://eu2.contabostorage.com/5f488f75cfa643c9ab46f210c815ab43:employees",
    "/stores": "https://eu2.contabostorage.com/5f488f75cfa643c9ab46f210c815ab43:stores",
    "/expenses": "https://eu2.contabostorage.com/5f488f75cfa643c9ab46f210c815ab43:expenses"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$5i8M7egjWW, defaults: {"maxAge":31536000,"modifiers":{"format":"webp"}} }
}
        